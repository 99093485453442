@font-face {
    font-family: 'Proxima Nova Regular';
    src: local('Proxima Nova Regular'), url('./proximanova-regular-webfont.ttf') format('truetype');
}

@font-face {
    font-family: 'Proxima Nova Regular';
    src: local('Proxima Nova Bold'), url('./proximanova-bold-webfont.ttf') format('truetype');
    font-weight: bold;
}

@font-face {
    font-family: 'Proxima Nova Regular';
    src: local('Proxima Nova Italic'), url('./proximanova-regular-italic-webfont.ttf') format('truetype');
    font-style: italic;
}

@font-face {
    font-family: 'Proxima Nova Regular';
    src: local('Proxima Nova Italic'), url('./proximanova-bold-italic-webfont.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}
